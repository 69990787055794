import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import ConfigContext from "../../ConfigContext";
import PostActions from "../PostActions/PostActions";
import "./PostContent.scss";

const PostContent = ({ post }) => {
  const contentRef = useRef();
  const image = (post.images && post.images.Box640) || post.images.Box320 || {};
  const content = post.content.replace(new RegExp("</?h1[^>]*>", "g"), "");
  const [showVideo, setshowVideo] = useState(false);
  const [featureVideo, setfeatureVideo] = useState(null);
  const { configData } = useContext(ConfigContext);
  useEffect(() => {
    contentRef.current.addEventListener("click", (e) => {
      e.preventDefault();
      e.target.href && window.open(e.target.href, "_blank");
    });
  }, []);

  const getPostVideoURL = () => {
    axios
      .get(`${configData.APP_URL}/post/${post.postId}?include=WatchVideo`)
      .then((videoRes) => {
        let featureVideo = videoRes.data.media.filter(
          (media) => media.role === "video-hosted"
        )[0];
        setfeatureVideo(featureVideo);
        setshowVideo(true);
      });
  };

  return (
    <div className="ab">
      {/* <button >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              width="27"
              height="27"
              overflow="visible"
              stroke="#f9f5f5"
              strokeLinecap="round"
            >
              <line x1="0" y1="0" x2="20" y2="20" />
              <line x1="20" y1="0" x2="0" y2="20" />
            </svg>
          </button> */}

      {/* <button data-v-f62a04d2="">
  <svg className="closesvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20" overflow="visible" stroke="black" stroke-width="2" stroke-linecap="round">
    <line className="closesvg" x1="0" y1="0" x2="20" y2="20"></line> 
    <line className="closesvg" x1="20" y1="0" x2="0" y2="20">
  </line></svg></button> */}
      {image.url && post.postType !== "Video" && (
        <img className="feature-image" src={image.url} />
      )}
      {post.postType === "Video" && !showVideo && (
        <div onClick={getPostVideoURL} className="feature-image">
          <img className="feature-image" src={image.url} />
          <img className="video-thumb " src="../../images/video_thumb.png" />
        </div>
      )}
      {showVideo && (
        <video
          src={featureVideo.url}
          width={featureVideo.width}
          className="feature-image video non-hero"
          autoPlay
          controls
        >
          Your browser does not support the video tag.
        </video>
      )}
      <div className="post-content">
        <h3>{post.title}</h3>
        <div
          className="content"
          ref={contentRef}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
        <PostActions
          openOriginal="true"
          postId={post.postId}
          postTitle={post.title}
          publishDate={post.publishDate}
          statistics={post.statistics}
          isLikedByUser={post.isLikedByUser}
        />
      </div>
    </div>
  );
};

export default PostContent;
