import React from 'react'
import './Loader.scss'

export default function Loader({showLoader}) {
    return (<>
    {showLoader ? <div id="loader-overlay">
        <div id="loader-box">
            <div className="loader"></div>
            <div className="loader-txt">Loading...</div>
        </div>
    </div> : null}
    </>)
}