import { createPortal } from "react-dom";
import React, { useRef, useEffect } from "react";
import "./Modal.scss";

const ReactPortal = ({ children }) => {
  return createPortal(children, document.getElementById("portal-root"));
};

const Modal = ({ children, handleClose, pdfView }) => {
  const onclickHendler = (e) => {
    // handleClose()
    // let clickArea = e.target
    // console.log("print class", e.target);
    // console.log(
    //   "check condition",
    //   e.target == "div.modalParent" ? true : false
    // );
    if (
      e.target.className == "modalParent" ||
      e.target.className == "modalsection"
    )
      handleClose();
  };
  return (
    <ReactPortal>
      <div className="modalParent" onClick={onclickHendler}>
        <div
          className="modalsection"
          style={{
            padding: pdfView ? "3rem 0 1rem 0" : "3rem 1rem 1rem 1rem;",
          }}
        >
          <button onClick={handleClose}>
            <svg
              onClick={handleClose}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              width="20"
              height="20"
              overflow="visible"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
            >
              <line className="closesvg" x1="0" y1="0" x2="20" y2="20"></line>
              <line className="closesvg" x1="20" y1="0" x2="0" y2="20"></line>
            </svg>
          </button>
          {children}
        </div>
      </div>
    </ReactPortal>
  );
};

export default Modal;
