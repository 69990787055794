import React, { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import HeadLineSlideShow from "../HeadLineSlideShow/HeadLineSlideShow";
import Navigation from "../Navigation/Navigation";
import "./Header.scss";
const Header = ({ posts, openModal }) => {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <div className="particlejs">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: {
              // value: "#0d47a1",
            },
          },
          fpsLimit: 60,
          interactivity: {
            //detect_on: "canvas",
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
              onclick: {
                enable: true,
                mode: "push",
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: true,
              opacity: 0.04,
              width: 1,
            },
            collisions: {
              enable: false,
            },
            move: {
              directions: "none",
              enable: true,
              out_mode: "out",
              bounce: false,
              random: false,
              speed: 1,
              straight: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
              }
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.05,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false
              }
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#000000"
              },
              polygon: {
                nb_sides: 5
              }
            },
            size: {
              value: { min: 1, max: 12 },
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false
              }
            },
          },
          detectRetina: true,
        }}
      />
      <div>
      <Navigation/>
      <HeadLineSlideShow posts={posts} openModal={openModal}></HeadLineSlideShow>
      </div>
      {/* <div>
        <nav className="navigation">
          <div>
            <div >
              <div className="logo">
                <a href="">
                  <img src="./images/logo.svg" alt="The download logo" />
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <p className="header-text">
                <i>
                  The Download is a{" "}
                  <strong>
                    DH&amp;I powered competitive intelligence product
                  </strong>{" "}
                  that delivers a curated and concise review of key health
                  technology market events.
                </i>
                <br />
                <br />
                <b>Not for external use, distribution or publication.</b>
              </p>
            </div>
          </div>
        </nav>
        <HeadLineSlideShow posts={posts}></HeadLineSlideShow>
      </div> */}
    </div>
  );
};
export default Header;
