import axios from "axios";
import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import Modal from "../../utils/Modal";
import Loader from "../Common/Loader";
import "./QuarterlyPerspective.scss";

const QuarterlyPerspective = ({ quarterlyPerspective }) => {
  const [numPage, setNumPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [showQuaterlyPerspective, setshowQuaterlyPerspective] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [activeTab, setactiveTab] = useState(quarterlyPerspective[0].tabName);

  function onDocumentLoadSucess({ numPages }) {
    setNumPage(numPages);
    setPageNumber(1);
    const timer = setTimeout(() => {
      setshowLoader(false);
    }, 3000);
    return () => clearTimeout(timer);
  }
  function changePage(offSet) {
    setPageNumber((prevPageNumber) => prevPageNumber + offSet);
  }

  function changePageBack() {
    changePage(-1);
  }
  function changePageNext() {
    changePage(+1);
  }

  const onPerspectiveView = (event) => {
    event.preventDefault();
    document.body.classList.add("noscroll");
    setshowQuaterlyPerspective(true);
    const payload = [
      {
        userId: localStorage.getItem("registerE"),
        userName: localStorage.getItem("userName"),
        quaterlyPerspectiveTimestamp: new Date(Date.now()).getTime(),
        className: "quaterly-perspective",
        dateTime: new Date(Date.now()).toISOString(),
        department: localStorage.getItem("department").split("/")[1].trim(),
      },
    ];
    axios
      .post(
        process.env.REACT_APP_API_URL + "download",
        JSON.stringify(payload),
        {
          headers: {
            "x-api-key": process.env.REACT_APP_X_API_KEY,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then((data) => {
        console.log("Quaterly Perspective success");
      })
      .catch((error) => {
        console.log(error);
      });
    setshowLoader(true);
  };
  return (
    <div>
      {(quarterlyPerspective && quarterlyPerspective[0].showPerspective) && (
        <div className="quarterly-perspective">
          <div className="container-fluid">
            <h2>{quarterlyPerspective[0].title}</h2>
            <p>
              <i style={{ fontSize: "20px" }}>
                Assessment of impactful topics and/or trends shaping the health
                tech landscape over the past quarter to inform Amgen's strategic
                choices.
              </i>
              <br />
              <i>
                Powered By R&D AI Strategy and Execution.
                <br />
              </i>
            </p>
            <p>
              <a
                href="#"
                onClick={onPerspectiveView}
                className="btn btn btn-outline-primary"
              >
                View
              </a>
            </p>
          </div>
        </div>
      )}

      {showQuaterlyPerspective && (
        <Modal
          handleClose={() => {
            setshowQuaterlyPerspective(false);
            document.body.classList.remove("noscroll");
          }}
          pdfView
        >
          <div className="pdf-modal">
            {showLoader ? <Loader showLoader /> : null}
            <div className="tabButton">
              {quarterlyPerspective.map((perspective) => {
                return (
                  <button
                    className={
                      activeTab === perspective.tabName
                        ? "btn active"
                        : "btn btn-outline-primary"
                    }
                    onClick={() => {
                      setactiveTab(perspective.tabName);
                      setshowLoader(true);
                      setTimeout(() => {
                        setshowLoader(false);
                      }, 3000);
                    }}
                  >
                    {perspective.tabName}
                  </button>
                );
              })}
            </div>
            {quarterlyPerspective.map((perspective) => {
              return (
                <>
                  {activeTab === perspective.tabName && ( 
            <center style={{ padding: "10px 5px" }}>
              <div>
                <Document
                  file={`${window.location.origin}/${perspective.prespectiveFileUrl}`}
                  loading=""
                  onLoadSuccess={onDocumentLoadSucess}
                >
                  {Array.from(new Array(numPage), (el, index) => (
                    <Page
                      key={`Page_${index + 1}`}
                      pageNumber={index + 1}
                      loading=""
                    ></Page>
                  ))}
                </Document>
              </div>
            </center>
            )}
                </>
              );
            })}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default QuarterlyPerspective;
