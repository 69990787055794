import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import "./PostActions.scss";
import ConfigContext from "../../ConfigContext";
import Loader from "../Common/Loader";

const PostActions = ({
  postId,
  condensed,
  light,
  openOriginal,
  postTitle,
  publishDate,
  isVideo,
  openModal,
  statistics,
}) => {
  const { configData } = useContext(ConfigContext);
  const [liked, setliked] = useState(false);
  const [likeCount, setlikeCount] = useState(0);
  const [showOptions, setshowOptions] = useState(false);
  const buttonClass = light ? "btn btn-outline-light" : "";
  const [showLoader, setshowLoader] = useState(false);

  // useEffect(() => {
  //   setlikeCount(statistics.likeCount);
  // }, [statistics]);

  useEffect(() => {
    if (configData) {
      axios
        .get(`${configData.APP_URL}/post/${postId}/like/list?limit=50`)
        .then((res) => {
          if (res.data && res.data.users && res.data.users.length > 0) {
            const userId = Number(localStorage.getItem("userId"));
            setlikeCount(res.data.users.length);
            if (res.data.users.find((user) => user.userId === userId)) {
              setliked(true);
            }
          }
        });
    }
  }, [configData]);

  const learnMore = (event) => {
    event.preventDefault();
    if (openOriginal) {
      window.open(
        `https://amgennews.amgen.com/member/post/${postId}`,
        "_blank"
      );
      const newspayload = [
        {
          userId: localStorage.getItem("registerE"),
          userName: localStorage.getItem("userName"),
          postId: postId,
          postTitle: postTitle,
          articleReadTimestamp: new Date(Date.now()).getTime(),
          className: "article-read-on-amgen",
          publishDate: publishDate,
          dateTime: new Date(Date.now()).toISOString(),
          department: localStorage.getItem("department").split("/")[1].trim(),
        },
      ];
      axios
        .post(
          process.env.REACT_APP_API_URL + "download",
          JSON.stringify(newspayload),
          {
            headers: {
              "x-api-key": process.env.REACT_APP_X_API_KEY,
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        )
        .then((data) => {
          console.log("article amgen news read success");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      openModal();
    }
  };

  const captureLikeMetrics = () => {
    const payload = [
      {
        userId: localStorage.getItem("registerE"),
        userName: localStorage.getItem("userName"),
        postId: postId,
        postTitle: postTitle,
        articleLikeTimestamp: new Date(Date.now()).getTime(),
        className: "article-like",
        publishDate: publishDate,
        dateTime: new Date(Date.now()).toISOString(),
        department: localStorage.getItem("department").split("/")[1].trim(),
      },
    ];
    axios
      .post(
        process.env.REACT_APP_API_URL + "download",
        JSON.stringify(payload),
        {
          headers: {
            "x-api-key": process.env.REACT_APP_X_API_KEY,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then((data) => {
        console.log("article like success");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const likePost = () => {
    captureLikeMetrics();
    if (!liked) {
      setshowLoader(true);
      const payload = {
        id: postId,
        userId: Number(localStorage.getItem("userId")),
        activitySource: "Web",
        activityReason: "Touchpoint",
        activityDetail: "voluptatem",
      };
      axios
        .post(`${configData.APP_URL}/post/${postId}/like`, payload)
        .then((res) => {
          if (res.data && res.data.code === "success") {
            setliked(true);
            setlikeCount(likeCount + 1);
            setshowLoader(false);
          }
        })
        .catch((err) => {
          setshowLoader(false);
        });
    } else {
      setshowLoader(true);
      axios
        .delete(
          `${
            configData.APP_URL
          }/post/${postId}/like?activitySource=Api&activityReason=Recommendation&activityDetail=dignissimos&userId=${localStorage.getItem(
            "userId"
          )}`
        )
        .then((res) => {
          if (res.data) {
            setliked(false);
            setlikeCount(likeCount - 1);
            setshowLoader(false);
          }
        })
        .catch((err) => {
          setshowLoader(false);
        });
    }
  };
  const commentPost = () => {
    const payload = [
      {
        userId: localStorage.getItem("registerE"),
        userName: localStorage.getItem("userName"),
        postId: postId,
        postTitle: postTitle,
        articleCommentTimestamp: new Date(Date.now()).getTime(),
        className: "article-comment",
        publishDate: publishDate,
        dateTime: new Date(Date.now()).toISOString(),
        department: localStorage.getItem("department").split("/")[1].trim(),
      },
    ];
    axios
      .post(
        process.env.REACT_APP_API_URL + "download",
        JSON.stringify(payload),
        {
          headers: {
            "x-api-key": process.env.REACT_APP_X_API_KEY,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then((data) => {
        console.log("article comment success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const shareViaSMS = (title, URL) => {
    navigator
      .share({
        // Title that occurs over
        // web share dialog
        title: title,

        // URL to share
        url: URL,
      })
      .then(() => {
        console.log("Thanks for sharing!");
      })
      .catch((err) => {
        // Handle errors, if occured
        console.log("Error while using Web share API:");
        console.log(err);
      });
  };

  return (
    <>
      {showLoader ? <Loader showLoader /> : null}
      <div
        className={
          light
            ? condensed
              ? "post-actions light condensed"
              : "post-actions light"
            : "post-actions"
        }
      >
        <ul>
          <li>
            <a onClick={learnMore} href="#" className={buttonClass}>
              {!openOriginal ? (
                isVideo ? (
                  <div className="read-post">Play</div>
                ) : (
                  <div className="read-post">Learn More</div>
                )
              ) : (
                <div>Read on Amgen news</div>
              )}
            </a>
          </li>
          {!light && (
            <li
              onMouseEnter={() => setshowOptions(true)}
              onMouseLeave={() => setTimeout(() => setshowOptions(false), 3000)}
            >
              <a style={{ cursor: "pointer" }}>
                <img
                  src="../../images/icons/share.svg"
                  height="18px"
                  width="18px"
                />
              </a>
              {showOptions && (
                <ul className="share-links">
                  <li>
                    <a
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${window.location.origin}/#${postId}`
                        );
                        setshowOptions(false);
                      }}
                    >
                      <img
                        src="../../images/icons/copy.png"
                        height="18px"
                        width="18px"
                        style={{ paddingBottom: "3px", paddingRight: "3px" }}
                      />
                      Copy Link
                    </a>
                  </li>
                  {navigator.share && (
                    <li>
                      <a
                        onClick={() => {
                          shareViaSMS(
                            postTitle,
                            `${window.location.origin}/#${postId}`
                          );
                          setshowOptions(false);
                        }}
                      >
                        <img
                          src="../../images/icons/sms.png"
                          height="18px"
                          width="18px"
                          style={{ paddingBottom: "3px", paddingRight: "3px" }}
                        />
                        Share via SMS
                      </a>
                    </li>
                  )}
                  <li>
                    <a
                      onClick={() => {
                        setshowOptions(false);
                      }}
                      href={`mailto:?Subject=${postTitle}&body=${window.location.origin}/#${postId}`}
                      target={postId}
                    >
                      <img
                        src="../../images/icons/mail.png"
                        height="18px"
                        width="18px"
                        style={{ paddingBottom: "3px", paddingRight: "3px" }}
                      />
                      Share via Email
                    </a>
                    <iframe
                      id={postId}
                      name={postId}
                      style={{ visibility: "hidden", position: "absolute" }}
                    ></iframe>
                  </li>
                </ul>
              )}
            </li>
          )}
          <li>
          <a onClick={likePost} style={{ cursor: "pointer" }}>
              {liked ? (
                light ? (
                  <img
                    src="../../images/icons/thumbs-up-white.png"
                    style={{ height: "18px", width: "18px" }}
                  />
                ) : (
                  <img
                    src="../../images/icons/thumbs-up.png"
                    style={{ height: "18px", width: "18px" }}
                  />
                )
              ) : light ? (
                <img
                  src="../../images/icons/thumbs-up-outline-white.svg"
                  style={{ paddingBottom: "3px", paddingRight: "3px" }}
                />
              ) : (
                <img
                  src="../../images/icons/thumbs-up-outline.svg"
                  style={{ paddingBottom: "3px", paddingRight: "3px" }}
                />
              )}
              {likeCount ? likeCount : ""}
            </a>
          </li>
          <li>
            <a
              onClick={commentPost}
              target="_blank"
              href={`https://amgennews.amgen.com/member/post/${postId}?showDiscussions=True`}
            >
              {light ? (
                <img
                  src="../../images/icons/comment-white.svg"
                  style={{ paddingBottom: "3px", paddingRight: "3px" }}
                />
              ) : (
                <img
                  src="../../images/icons/comments.png"
                  height="18px"
                  width="18px"
                />
              )}

              {statistics &&
              statistics.commentCount &&
              statistics.commentCount > 0
                ? statistics.commentCount
                : ""}
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default PostActions;
