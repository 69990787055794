import React, { useContext, useEffect, useState } from "react";
import ConfigContext from "../../ConfigContext";
import Post from "../Posts/Posts";
import "./PostSlider.scss";

const PostSlider = ({ posts, openModal}) => {
  const [currentSlide, setcurrentSlide] = useState(1);
  const [navList, setnavList] = useState([]);
  const [postsLeft, setpostsLeft] = useState([]);
  const [postsRight, setpostsRight] = useState([]);
  const { configData } = useContext(ConfigContext);

  useEffect(() => {
    let length = Math.ceil(posts.length / 4)
    setnavList(new Array(length).fill(''));
    let left = [];
    let right = [];
    if (currentSlide === 1) {
      left = [...posts].slice(0, 1);
      right = [...posts].slice(1, 4);
      setpostsLeft(left);
      setpostsRight(right);
    } else if (currentSlide === 2) {
      left = [...posts].slice(4, 7);
      right = [...posts].slice(7, 10);
      setpostsLeft(left);
      setpostsRight(right);
    } else {
      left = [...posts].slice(10, 13);
      right = [...posts].slice(13, 16);
      setpostsLeft(left);
      setpostsRight(right);
    }
  }, [currentSlide]);

  const totalSlidesCount = () => {
    if (posts.length < 4) return 1;
    const otherSlides = posts.length - 4; // First slide has 4 items;
    return Math.ceil(otherSlides / 6) + 1;
  };
  const totalSlides = totalSlidesCount();
  const prevSlide = () => {
    if (currentSlide === 1) {
      // Go to last slide
      currentSlide = totalSlides;
    } else {
      currentSlide = currentSlide - 1;
    }
  };
  const nextSlide = () => {
    if (currentSlide < totalSlides) {
      // Go to last slide
      currentSlide = currentSlide + 1;
    } else {
      currentSlide = 1;
    }
  };

  return (
    <div>
      {posts.length && (
        <div className="post-slider">
          <section>
            <div className={currentSlide === 1 ? "no-border left" : "left"}>
            {postsLeft.map((post) => {
                return (
                  <div key={post.postId}>
                    <Post post={post} openModal={openModal} hero={1 === currentSlide} />
                  </div>
                );
              })}
            </div>
            {/* <div>
              {posts.map((post, index) => {
                const pst = configData.posts.find(pst => pst.id === post.postId);
                const graybg = pst && pst.category ? true : false;
                const typeclass = (index + 1) %2 ===0 ? "even-slider" : 'odd-slider'
                return (
                  <div key={post.postId} className={graybg ? "slidergraybg" : typeclass}>
                    <Post post={post} hero={1 === currentSlide} />
                    <Post
                      border={graybg ? false : true}
                      post={post}
                      showbroder={true}
                     
                      index={index}
                    />
                  </div>
                );
              })}
            </div> */}
            <div className="right">
                  {postsRight.map((post) => {
                    return (
                      <div key={post.postId}>
                        <Post post={post} openModal={openModal} />
                      </div>
                    );
                  })}
                </div> 
          </section>
          {/* <section>
            {showLoadMore && <div>
              <button className="load-more" onClick={loadMore}>
                Load More
              </button>
            </div>}
          </section> */}
          {navList.length > 1 && (
                <nav className="dots">
                  {navList.map((dot, index) => {
                    return (
                      <a
                        onClick={() => setcurrentSlide(index + 1)}
                        key={index}
                        className={currentSlide === index + 1 ? 'active' : ''}
                      ></a>
                    );
                  })}
                </nav>
              )}
        </div>
      )}
      {posts.length === 0 && (
        <div className="post-slider no-posts">
          <h3>There are no articles to view for the current release.</h3>
          <a className="h3" href="https://amgennews.amgen.com" target="_blank">
            VIEW PAST ARTICLES
          </a>
        </div>
      )}
    </div>    
  );
};

export default PostSlider;
