import axios from "axios";
import React, { useContext, useState } from "react";
import ConfigContext from "../../ConfigContext";
import PostActions from "../PostActions/PostActions";
import "./Posts.scss";

const Post = ({ post, hero, card, openModal }) => {
  const [showVideo, setshowVideo] = useState(false);
  const [featureVideo, setfeatureVideo] = useState(null);
  const { configData } = useContext(ConfigContext);
  const image = (post.images && post.images.Box640) || post.images.Box320 || {};

  const getPostVideoURL = () => {
    axios
      .get(`${configData.APP_URL}/post/${post.postId}?include=WatchVideo`)
      .then((videoRes) => {
        let featureVideo = videoRes.data.media.filter(
          (media) => media.role === "video-hosted"
        )[0];
        setfeatureVideo(featureVideo);
        setshowVideo(true);
      });
  };

  const openModalFromPostAction = () => {
    openModal(post);
  };

  return (
    <>
      <div className={card ? "post card" : "post standard"}>
        {hero ? (
          <div>
            {image.url && post.postType !== "Video" && (
              <div
                onClick={() => openModal(post)}
                className="feature-image hero"
                style={{ backgroundImage: `url(${image.url})` }}
              >
                {post.perspective && card && (
                  <div>
                    <div className="perspective-badge-border"></div>
                    <div className="perspective-badge">
                      <img
                        className="chat-icon"
                        src="../../images/icons/TheDownload_mark.svg"
                      />
                      <span>Perspective</span>
                      <div className="perspective-tooltip">
                        Take a deeper dive into this article through commentary
                        provided by DH&I, where we assess this headline in
                        context of other market activity and interpret the ‘so
                        what’ for Amgen
                      </div>
                    </div>
                  </div>
                )}
                {(post.title.indexOf('ChatGPT') != -1 || post.title.indexOf('Whitepaper') != -1) && card && (
                  <div>
                    <div className="perspective-whitepaper-badge-border"></div>
                    <div className="perspective-whitepaper-badge">
                      <img
                        className="chat-icon"
                        src="../../images/icons/magnifying-glass.png"
                        height="18px"
                        width="18px"
                      />
                      <span>White Paper</span>
                    </div>
                  </div>
                )}
              </div>
            )}
            {post.postType === "Video" && !showVideo && (
              <div
                onClick={getPostVideoURL}
                className="feature-image hero"
                style={{ backgroundImage: `url(${image.url})` }}
              >
                <img
                  className="video-thumb "
                  src="../../images/video_thumb.png"
                />
                {post.perspective && card && (
                  <div>
                    <div className="perspective-badge-border"></div>
                    <div className="perspective-badge">
                      <img
                        className="chat-icon"
                        src="../../images/icons/TheDownload_mark.svg"
                      />
                      <span>Perspective</span>
                      <div className="perspective-tooltip">
                        Take a deeper dive into this article through commentary
                        provided by DH&I, where we assess this headline in
                        context of other market activity and interpret the ‘so
                        what’ for Amgen
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {showVideo && (
              <div>
                <video
                  src={featureVideo.url}
                  width={featureVideo.width}
                  className="feature-image video hero"
                  autoPlay
                  muted
                  controls
                >
                  Your browser does not support the video tag.
                </video>
                {post.perspective && card && (
                  <div>
                    <div className="perspective-badge-border"></div>
                    <div className="perspective-badge">
                      <img
                        className="chat-icon"
                        src="../../images/icons/TheDownload_mark.svg"
                      />
                      <span>Perspective</span>
                      <div className="perspective-tooltip">
                        Take a deeper dive into this article through commentary
                        provided by DH&I, where we assess this headline in
                        context of other market activity and interpret the ‘so
                        what’ for Amgen
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="content">
              <h3 className="clamp-3" style={{ cursor: "pointer" }}>
                <a onClick={() => openModal(post)}>{post.title}</a>
              </h3>
              <p className="clamp-4">{post.description}</p>

              <PostActions
                postId={post.postId}
                postTitle={post.title}
                publishDate={post.publishDate}
                isLikedByUser={post.isLikedByUser}
                isVideo={post.postType === "Video"}
                openModal={openModalFromPostAction}
                statistics={post.statistics}
              />
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-4">
              {image.url && post.postType !== "Video" && (
                <div
                  onClick={() => openModal(post)}
                  className="feature-image non-hero"
                  style={{ backgroundImage: `url(${image.url})` }}
                >
                  {post.perspective && card && (
                    <div>
                      <div className="perspective-badge-border"></div>
                      <div className="perspective-badge">
                        <img
                          className="chat-icon"
                          src="../../images/icons/TheDownload_mark.svg"
                        />
                        <span>Perspective</span>
                        <div className="perspective-tooltip">
                          Take a deeper dive into this article through
                          commentary provided by DH&I, where we assess this
                          headline in context of other market activity and
                          interpret the ‘so what’ for Amgen
                        </div>
                      </div>
                    </div>
                  )}
                {(post.tagLine.indexOf('ChatGPT') != -1 || post.tagLine.indexOf('Whitepaper') != -1) && card && (
                  <div>
                    <div className="perspective-whitepaper-badge-border"></div>
                    <div className="perspective-whitepaper-badge">
                      <img
                        className="chat-icon"
                        src="../../images/icons/magnifying-glass.png"
                        height="18px"
                        width="18px"
                      />
                      <span>White Paper</span>
                    </div>
                  </div>
                )}
                </div>
              )}
              {post.postType === "Video" && !showVideo && (
                <div
                  onClick={getPostVideoURL}
                  className="feature-image hero"
                  style={{ backgroundImage: `url(${image.url})` }}
                >
                  <img
                    className="video-thumb "
                    src="../../images/video_thumb.png"
                  />
                  {post.perspective && card && (
                    <div>
                      <div className="perspective-badge-border"></div>
                      <div className="perspective-badge">
                        <img
                          className="chat-icon"
                          src="../../images/icons/TheDownload_mark.svg"
                        />
                        <span>Perspective</span>
                        <div className="perspective-tooltip">
                          Take a deeper dive into this article through
                          commentary provided by DH&I, where we assess this
                          headline in context of other market activity and
                          interpret the ‘so what’ for Amgen
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {showVideo && (
                <div>
                  <video
                    src={featureVideo.url}
                    width={featureVideo.width}
                    className="feature-image video hero"
                    autoPlay
                    muted
                    controls
                  >
                    Your browser does not support the video tag.
                  </video>
                  {post.perspective && card && (
                    <div>
                      <div className="perspective-badge-border"></div>
                      <div className="perspective-badge">
                        <img
                          className="chat-icon"
                          src="../../images/icons/TheDownload_mark.svg"
                        />
                        <span>Perspective</span>
                        <div className="perspective-tooltip">
                          Take a deeper dive into this article through
                          commentary provided by DH&I, where we assess this
                          headline in context of other market activity and
                          interpret the ‘so what’ for Amgen
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="col-lg-8">
              <div className="content">
                <h3 className="clamp-3" style={{ cursor: "pointer" }}>
                  <a onClick={() => openModal(post)}>{post.title}</a>
                </h3>
                <p className="clamp-4">{post.description}</p>

                <PostActions
                  postId={post.postId}
                  postTitle={post.title}
                  publishDate={post.publishDate}
                  isLikedByUser={post.isLikedByUser}
                  isVideo={post.postType === "Video"}
                  openModal={openModalFromPostAction}
                  statistics={post.statistics}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Post;
