import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from 'react';
import ConfigContext from "../../ConfigContext";
import './Footer.scss';


const Footer = ({appReleaseIndex, startDate, endDate}) => {
    return (
        <>
            <footer>
                <div>
                    <img src='./images/logo-dark.svg' />
                </div>
                <div>
                    <strong>{appReleaseIndex === 0 && 'Latest'} News { startDate } - { endDate }</strong>
                </div>
                <div>
                    <img src='./images/amgen-logo.svg' />
                </div>
            </footer>
        </>
    )
}
export default Footer;