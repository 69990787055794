import React from "react";
import './CategoryTab.scss'

function CategoryTab({title, icon, image, selected, click}) {
  return (
    <div
      className={selected ? "category-tab selected" : "category-tab"}
      onClick={click}
      style={{ background: `url(${image})` }}
    >
      <div
        className="mask"
      ><img src="../../images/arrow-down-mask.svg" /></div>
      {icon && <div className="icon">
        <img src={icon} />
      </div>}
      <h3>{title}</h3>
    </div>
  );
}

export default CategoryTab;
