import React from 'react';
import './Navigation.scss';

const Navigation = () => {
    return (
            <nav className='navigation'>
                <div>
                    <a href=""><img src="./images/logo.svg" alt="The download logo"/></a>
                </div>
                <div>
                    <p><i>The Download is a <strong>RAISE powered competitive intelligence product</strong> that delivers a curated and concise review of key health technology market events.</i><br /><br /><b>Not for external use, distribution or publication.</b></p>
                </div>
            </nav>
    )
}
export default Navigation;