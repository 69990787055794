import React, { useState, useEffect, useRef } from "react";
import "./App.scss";
import ConfigContext from "./ConfigContext";
import Home from "./components/Home/Home";
import Modal from "./utils/Modal";
import axios from "axios";
import Loader from "./components/Common/Loader";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";

function App() {
  const [alertCollapse, setalertCollapse] = useState(false);
  const [showQuaterlyPerspective, setshowQuaterlyPerspective] = useState(false);
  const [configData, setconfigData] = useState(null);
  const [title, settitle] = useState(null);
  const [prespectiveFileUrl, setprespectiveFileUrl] = useState("");
  const [showLoader, setshowLoader] = useState(false);
  const [numPage, setNumPage] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const headerRef = useRef();
  const [collapseAlert, setcollapseAlert] = useState(false);
  const [scrollPosition, setscrollPosition] = useState(1200);

  useEffect(() => {
    axios.get("config.json").then((res) => {
      let response = res.data;
      console.log(response);
      setconfigData({
        ...response,
        posts: response.app_release[0].posts,
        //featuredPosts: response.featuredPosts.sort((a, b) => a.order - b.order),
        //posts: response.posts.sort((a, b) => a.order - b.order),
      });
      localStorage.setItem("x-api-key", process.env.REACT_APP_X_API_KEY);
      localStorage.setItem(
        "USER_METRICS_URL",
        process.env.REACT_APP_API_URL + "download"
      );

      if (
        response.quarterlyPerspective[0] &&
        response.quarterlyPerspective[0].title &&
        response.quarterlyPerspective[0].prespectiveFileUrl &&
        response.quarterlyPerspective[0].alert
      ) {
        settitle(response.quarterlyPerspective[0].alertTitle);
        setprespectiveFileUrl(
          response.quarterlyPerspective[0].prespectiveFileUrl
        );
        // onAlert({
        //   title: "New Quarterly Perspective is here!",
        //   prespectiveFileUrl: res.data.quarterlyPerspective.prespectiveFileUrl,
        // });
      }
    });

    window.addEventListener("scroll", (e) => {
      if (window.scrollY > scrollPosition) {
        setcollapseAlert(true);
      } else {
        setcollapseAlert(false);
      }
    });
    return () => {
      window.removeEventListener("scroll", setcollapseAlert(false));
    };
  }, []);

  const onDocumentLoadSucess = ({ numPages }) => {
    setNumPage(numPages);
    setPageNumber(1);
    const timer = setTimeout(() => {
      setshowLoader(false);
    }, 3000);
    return () => clearTimeout(timer);
  };

  const onPerspectiveView = (event) => {
    event.preventDefault();
    document.body.classList.add("noscroll");
    setshowQuaterlyPerspective(true);
    const payload = [
      {
        userId: localStorage.getItem("registerE"),
        userName: localStorage.getItem("userName"),
        quaterlyPerspectiveTimestamp: new Date(Date.now()).getTime(),
        className: "quaterly-perspective",
        dateTime: new Date(Date.now()).toISOString(),
        department: localStorage.getItem("department").split("/")[1].trim(),
      },
    ];
    axios
      .post(
        process.env.REACT_APP_API_URL + "download",
        JSON.stringify(payload),
        {
          headers: {
            "x-api-key": process.env.REACT_APP_X_API_KEY,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then((data) => {
        console.log("Quaterly Perspective success");
      })
      .catch((error) => {
        console.log(error);
      });
    setshowLoader(true);
  };

  return (
    <>
      <ConfigContext.Provider value={{ configData }}>
        <div>
          <div className="wrapper">
            {prespectiveFileUrl && (
              <div className={collapseAlert ? "alert collapse" : "alert"}>
                <div className="icon">
                  <svg
                    style={{ width: "24px", height: "24px" }}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M21,19V20H3V19L5,17V11C5,7.9 7.03,5.17 10,4.29C10,4.19 10,4.1 10,4A2,2 0 0,1 12,2A2,2 0 0,1 14,4C14,4.1 14,4.19 14,4.29C16.97,5.17 19,7.9 19,11V17L21,19M14,21A2,2 0 0,1 12,23A2,2 0 0,1 10,21M19.75,3.19L18.33,4.61C20.04,6.3 21,8.6 21,11H23C23,8.07 21.84,5.25 19.75,3.19M1,11H3C3,8.6 3.96,6.3 5.67,4.61L4.25,3.19C2.16,5.25 1,8.07 1,11Z"
                    />
                  </svg>
                </div>
                <div className="title">{title}</div>
                <a className="href" onClick={onPerspectiveView} href="#">
                  VIEW
                </a>
              </div>
            )}
            <div
              ref={headerRef}
              className="header-background"
              style={{
                top: prespectiveFileUrl
                  ? collapseAlert
                    ? "40px"
                    : "60px"
                  : "0",
              }}
            ></div>
            <div
              className="page-content"
              style={{ top: prespectiveFileUrl ? "60px" : "0" }}
            >
              <Home />
            </div>

            {/* <div ref={fadeRef} className="fade"></div>
            <div className="fade-mask"></div> */}
          </div>
          {showQuaterlyPerspective && (
            <Modal
              pdfView
              handleClose={() => {
                setshowQuaterlyPerspective(false);
                document.body.classList.remove("noscroll");
              }}
            >
              <div className="pdf-modal">
                {showLoader ? <Loader showLoader /> : null}

                <center>
                  <div>
                    <Document
                      file={`${window.location.origin}/${prespectiveFileUrl}`}
                      loading=""
                      onLoadSuccess={onDocumentLoadSucess}
                    >
                      {Array.from(new Array(numPage), (el, index) => (
                        <Page
                          key={`Page_${index + 1}`}
                          pageNumber={index + 1}
                          loading=""
                        ></Page>
                      ))}
                    </Document>
                  </div>
                </center>
              </div>
            </Modal>
          )}
        </div>
      </ConfigContext.Provider>
    </>
  );
}

export default App;
